<template>
  <div class="home">
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="clearfix">
      <div class="container" data-aos="fade-up">
        <div class="hero-info" data-aos="zoom-in" data-aos-delay="100">
          <h2>Let’s make learning fun again!</h2>
          <button class="btn-style3"><a href="#explore">EXPLORE</a></button>
        </div>
      </div>
    </section>
    <!-- End Hero Section -->
    <!-- ======= About Section ======= -->
    <section id="about">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <p>
            In today’s hyper-connected world, democratizing access to
            educational resources that help learners think rather than cram
            continues to remain a challenge.
          </p>
        </header>
      </div>
    </section>
    <!-- End About Section -->
    <section id="about" class="mission-section">
      <div class="container">
        <div class="row justify-content-center about-extra">
          <div
            class="services-style-one col-lg-6 col-md-6 zoom align-items-stretch aos-init aos-animate"
          >
            <div class="inner-box vision-height">
              <div class="icon-box">
                <img src="/assets/img/eye.png" width="70" />
              </div>
              <h3 class="pt-3">VISION</h3>
              <p class="tj">
                Help high schoolers overcome their fear of Mathematics by
                providing them access to customized resources and building
                personalised learning journeys.
              </p>
            </div>
          </div>
          <div class="services-style-one zoom col-lg-6 col-md-6">
            <div class="inner-box">
              <div class="icon-box">
                <img src="/assets/img/mission.png" width="50" />
              </div>
              <h3 class="pt-3">MISSION</h3>
              <p class="tj">
                Leverage technology to build an ecosystem of resources and
                services that help high schoolers become thinkers who rely on
                conceptual clarity rather than muscle memory for academic
                success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= Product Section Starts======= -->
    <section
      id="explore"
      class="text-white services section-bg section-product"
    >
      <div class="container">
        <div class="section-title1">
          <p class="text-center text-white">EXPLORE</p>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div
              id="carouselExampleSlidesOnly"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="mt-5 row justify-content-center">
                    <div
                      class="col-lg-6 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <h3 class="mt-5 vision-title">Mobile App</h3>
                      <h5 class="mt-4 ft-26">
                        <i>Build a Solid Foundation and Ace your Exams</i>
                      </h5>
                      <p class="mt-4 text-vision-mission text-justify ft-24">
                        We believe that the right way to learn is by doing not
                        just watching. Our mobile app allows you to explore and
                        watch different hands-on model making videos so that you
                        can build and learn Math and Science concepts
                        simultaneously. We don’t let you rote learn, instead, we
                        show you how the world of Math and Science work!
                      </p>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 align-items-center"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <!-- <img src="assets/img/discover/mobile-app.png" width="100%" class="mx-auto d-block"> -->
                      <div
                        id="carouselExampleControls"
                        class="carousel slide mx-auto"
                        data-bs-ride="carousel"
                      >
                        <div id="fstcarousel" class="carousel-inner">
                          <div class="carousel-item active">
                            <img
                              src="../../public/assets/img/MobileApp1.png"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              src="../../public/assets/img/MobileApp2.png"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              src="../../public/assets/img/MobileApp3.png"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              src="../../public/assets/img/MobileApp4.png"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                        </div>
                        <button
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                      <div
                        class="mx-auto align-self-center mx-auto d-block mt-5"
                      >
                        <a href="#"
                          ><img
                            src="../../public/assets/img/store-logo.png"
                            width="30%"
                            class="img-fluid img-styl"
                            alt=""
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div id="science-kit" class="services section-product">
                    <div class="row align-items-center mt-4">
                      <div
                        class="col-lg-6 col-md-6 align-items-stretch"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                      >
                        <div
                          id="carouselExampleIndicators"
                          class="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div class="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="0"
                              class="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="3"
                              aria-label="Slide 4"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="4"
                              aria-label="Slide 5"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img
                                src="assets/img/Copy of P1010078.jpg"
                                width="100%"
                                height="100%"
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src="assets/img/Copy of P1010086.jpg"
                                width="100%"
                                height="100%"
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src="assets/img/Copy of P1010090.jpg"
                                width="100%"
                                height="100%"
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src="assets/img/Copy of P1010094.jpg"
                                width="100%"
                                height="100%"
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src="assets/img/Copy of Screenshot 2022-07-29 at 4.33.16 PM.png"
                                width="100%"
                                height="100%"
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <button
                            class="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            class="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-md-6 align-items-stretch"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                      >
                        <h3 class="vision-title float-left">
                          Natkhat STEM Kits
                        </h3>
                        <h5><i>Build and Learn, anytime and anywhere</i></h5>
                        <p class="text-vision-mission">
                          We believe in learning by doing. Through our Natkhat
                          Kits, anyone can build experiments which are aligned
                          with the school curriculum. All the items required
                          will be available in a neatly packaged box. You can
                          build it at home or school or park or anywhere you
                          want.
                        </p>
                        <button class="btn-style3">
                          <a
                            href="https://tan-ninety.myshopify.com/"
                            target="_blank"
                            >SHOP NOW</a
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row align-items-center">
                    <div
                      class="col-lg-6 col-md-6 align-items-center"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <h3 class="mt-5 vision-title">ERGO Project</h3>
                      <h5 class="mt-4">
                        <i>Get hands on with mathematical concepts digitally</i>
                      </h5>
                      <p class="text-vision-mission">
                        Doing mathematics doesn't have to be only about working
                        out with pen and paper or making physical representative
                        models. Ergo envisions mathematical concepts as digital
                        interactive lessons which you can play with and learn
                        from
                      </p>
                    </div>
                    <div
                      class="col-lg-6 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <div
                        id="carouselExampleIndicators"
                        class="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div class="carousel-indicators">
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="0"
                            class="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                        </div>
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img
                              src="assets/img/slide 15.png"
                              width="100%"
                              height="100%"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div class="carousel-item">
                            <img
                              src="assets/img/slide 16.png"
                              class="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <!-- <div class="carousel-item">
                  <img src="assets/img/discover/science3.jpg" class="d-block w-100" alt="..." />
                </div> -->
                        </div>
                        <button
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>

                      <button
                        class="btn-style btn-styl ml-3 mt-4"
                        @click="login"
                      >
                        PLAY GAME
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section id="science-kit" class="services section-product">
      <div id="science-kit" class="container services section-product"></div>
    </section> -->

    <section id="ergo" class="text-white services section-bg section-product">
      <div class="container"></div>
    </section>
    <!-- Product Section Ends-->
    <!-- Team Section -->
    <Team />
    <!-- Contact section -->
    <Contact />
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from "./Contact.vue";
import Team from "./Team.vue";
export default {
  name: "Home",
  components: {
    Contact,
    Team,
  },
};
</script>
