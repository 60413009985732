<template>
  <iframe
    src="https://arun-nd.github.io/unitygame/"
    width="100%"
    height="1000px"
    class="mt-5"
  ></iframe>
</template>

<script>
export default {
  name: "Play",
};
</script>
